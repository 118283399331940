import Head from 'next/head'
import Image from 'next/image'
import React from 'react';
import moment from 'moment';
import { Dna } from 'react-loader-spinner';
import GamesTable from '../components/GamesTable';
import {b64toBlob} from '../lib/b64toblob';
import firebase, {firebaseConfig} from '../lib/firebase'
import { toast } from 'react-toastify';
import Home from './home';

import 'firebase/compat/remote-config';

const LoaderSpinnerOverlay = (props: any) => {
  // @ts-ignore
  let {showLoader} = props
  return showLoader ? (
    <div style={{
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: '1000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Dna
        visible={showLoader}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{}}
        wrapperClass="dna-wrapper"
      />
    </div>
  ) : null

}

export default function Index() {

  const [loginDetails, setLoginDetails] = React.useState({
    email: '',
    password: '',
  })
  const [showSpinnerOverlay, setShowSpinnerOverlay] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const [remoteConfigInst, setRemoteConfigInst] = React.useState(null);

  const resetPassword = async () => {
    let email = prompt('Enter your email address');
    setShowSpinnerOverlay(true);

    if (!email) {
      toast.error('Email is required', {autoClose: 1000})
      setShowSpinnerOverlay(false);
      return;
    } else {
      firebase.auth().sendPasswordResetEmail(email).then(() => {
        // Email sent.
        toast.success(`Password reset email sent to: ${email}`, {autoClose: 1000})
        setShowSpinnerOverlay(false);
      }).catch((error) => {
        // An error happened.
        toast.error(error.message)
        setShowSpinnerOverlay(false);
      });
    }

  }

  const login = async () => {
    setShowSpinnerOverlay(true);
    firebase.auth().signInWithEmailAndPassword(loginDetails.email, loginDetails.password).then((userCredential: any) => {
      // Signed in
      var user = userCredential.user;
      // ...
      console.log(user)
      setShowSpinnerOverlay(false);
      toast.success('Logged in successfully', {autoClose: 1000})
      setIsLoggedIn(true);
    })
    .catch((error: any) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      toast.error(errorMessage)
      setShowSpinnerOverlay(false)
    });
  }

  React.useEffect(() => {
    return firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        // ...
        setIsLoggedIn(true);
      } else {
        // User is signed out
        // ...
        setIsLoggedIn(false);
      }
    });
  })
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const remoteConfig = firebase.remoteConfig();
      // @ts-ignore
      setRemoteConfigInst(remoteConfig);
      remoteConfig.settings.minimumFetchIntervalMillis = 120000;

      remoteConfig.fetchAndActivate()
        .then(() => {
          console.log('Remote config activated and loaded')
        })
        .catch((err) => {
          console.log(err);
        });
        //@ts-ignore
        // window.remoteConfig = remoteConfig;
    }
  }, []);

  return (
    <>
      <Head>
        <title>FixtureList - Login</title>
        <meta name="description" content="Generate custom PDFs based on matches stored in Dribl" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="mx-10" style={{maxWidth: '100vw'}}>
        <br />
        <LoaderSpinnerOverlay showLoader={showSpinnerOverlay} />
        <h1 className='text-3xl text-center mt-5'>FixtureList</h1>
        {isLoggedIn ? <Home remoteConfig={remoteConfigInst} /> : <div className='text-lg mt-10 text-center'>
          <input className='bg-slate-200 rounded-md px-2' style={{marginBottom: '20px'}} type="email" placeholder="Email:" onChange={(e) => {setLoginDetails({...loginDetails, email: e.target.value})}}></input>
          <br />
          <input className='bg-slate-200 rounded-md px-2' style={{marginBottom: '20px'}} type="password" placeholder="Password:" onChange={(e) => {setLoginDetails({...loginDetails, password: e.target.value})}}></input>
          <br />
          <button onClick={() => login()} className='bg-slate-200 rounded-md px-2' style={{marginBottom: '20px'}}>Login</button>
          <br />
          <button onClick={() => resetPassword()} className='bg-slate-200 rounded-md px-2'>Reset Password</button>
        </div>}
      </main>
    </>
  )
}
